// Routes.jsx
import React, { Suspense, lazy, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import GunbotStorage, { INITIAL_GUNBOT_SETUP_MODE } from 'assets/js/gunbot.storage';
import PropTypes from 'prop-types';
import getLicenseType from 'components/Gunbot/LicenseLevel';
import useBitrageActivated from 'hooks/useBitrageActivated';
import _ from 'lodash';

const validPathnames = [
  "/", 
  "/login",
  "/register",                   
  "/uta-config",          
  "/server-console",      
  "/code-editor",         
  "/tradingview-addon",   
  "/setup",               
  "/trading-settings",    
  "/api-slots",           
  "/notes",               
  "/bitrage",             
  "/bitrage-settings",    
  "/autoconfig",          
  "/chart"                
];

// Lazy-loaded components for code-splitting
const BitrageDashboard = lazy(() => import('views/Bitrage/Dashboard'));
const CodeEditor = lazy(() => import('views/CodeEditor'));
const TradingviewAddon = lazy(() => import('views/TradingviewAddon'));
const AutoConfig = lazy(() => import('views/AutoConfig'));
const BitrageSettings = lazy(() => import('views/Bitrage/Settings/index'));
const Setup = lazy(() => import('views/StartupGunbotSetup'));
const TradingSettings = lazy(() => import('views/TradingSettings'));
const UTAConfigurator = lazy(() => import('views/UTAConfigurator'));
const ServerConsole = lazy(() => import('views/ServerConsole'));
const Dashboard = lazy(() => import('views/Dashboard'));
const Notes = lazy(() => import('components/Gunbot/Notes'));

/**
 * LoadingFallback Component
 * Provides a user-friendly loading indicator during lazy loading.
 */
const LoadingFallback = () => (
  <div
    style={{
      backgroundColor: '#222',
      minWidth: '100%',
      minHeight: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
    }}
  >
    <span>Loading...</span>
  </div>
);

/**
 * RouteComponent
 * Handles the routing logic of the application with optimized performance.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - Gunbot config object.
 */
const RouteComponent = ({ config }) => {
  /**
   * Retrieve and memoize the initial Gunbot setup mode from storage.
   */
  const initialGunbotSetupMode = useMemo(
    () => GunbotStorage.get(INITIAL_GUNBOT_SETUP_MODE, ''),
    []
  );

  const licenseTypeLower = useMemo(() => getLicenseType(config).toLowerCase(), [config]);
  const shouldShowAlertAddon = useMemo(
    () => ['ultimate', 'tv', 'mm', 'br'].some((type) => licenseTypeLower.includes(type)),
    [licenseTypeLower]
  );
  const bitRageActive = useBitrageActivated();

  // Memoize derived values to prevent unnecessary computations
  const isNotDemo = useMemo(() => {
    return _.isNil(config.GUI?.demo) || config.GUI.demo === false;
  }, [config.GUI?.demo]);

  const isExchangesEmpty = useMemo(() => {
    return _.isEmpty(config.exchanges);
  }, [config.exchanges]);

  const gunthy_wallet = useMemo(() => config.bot.gunthy_wallet || '', [config.bot.gunthy_wallet]);

  /**
   * Determine if a redirect to a specific setup step is required.
   */
  const shouldRedirect = useMemo(() => initialGunbotSetupMode === '#advanced', [initialGunbotSetupMode]);

  /**
   * Define the set of routes based on the current props and application state.
   */

  // Memoize the routes that depend on config
  const memoizedRoutes = useMemo(() => {
    // Helper function to render the TradingSettings route with conditional redirect
    const renderTradingSettings = (props) =>
      shouldRedirect ? <Redirect to="/setup#step4" /> : <TradingSettings {...props} />;

    // Common routes when exchanges are not empty and wallet is present
    const commonRoutes = (
      <>
        <Route path="/" exact component={Dashboard} />
        <Route path="/uta-config" component={UTAConfigurator} />
        <Route path="/server-console" component={ServerConsole} />
        <Route path="/code-editor" component={CodeEditor} />
        {shouldShowAlertAddon && <Route path="/tradingview-addon" component={TradingviewAddon} />}
        <Route path="/setup" component={Setup} />
        <Route path="/trading-settings" render={renderTradingSettings} />
        <Route path="/api-slots" component={Setup} />
        <Route
          path="/notes"
          render={() => <Notes currentPair="all" currentExchange="all" useOverlay={true} />}
        />
        {bitRageActive && (
          <>
            <Route path="/bitrage" exact component={BitrageDashboard} />
            <Route path="/bitrage-settings" component={BitrageSettings} />
          </>
        )}

        {
          !validPathnames.some(path => 
            window.location.pathname === path || window.location.pathname.startsWith(path) && window.location.pathname.charAt(path.length) === ''
          ) && <Redirect to={bitRageActive ? '/bitrage' : '/'} />
        }

      </>
    );

    // Routes specific to licenseType 'one' and non-demo sessions
    if (licenseTypeLower === 'one' && isNotDemo) {
      if (isExchangesEmpty) {
        return (
          <>
            <Route path="/setup" exact component={Setup} />
            <Redirect to="/setup" />
          </>
        );
      } else {
        return (
          <>
            <Route path="/" exact component={Dashboard} />
            <Route path="/setup" component={Setup} />
            <Route path="/trading-settings" component={TradingSettings} />
            <Redirect to="/" />
          </>
        );
      }
    }

    // Routes when exchanges are empty or wallet is absent in non-demo sessions
    if ((isExchangesEmpty || gunthy_wallet === '') && isNotDemo) {
      return (
        <>
          <Route path="/setup" exact component={Setup} />
          <Redirect to="/setup" />
        </>
      );
    }

    // Default routes when none of the above conditions are met
    return commonRoutes;
  }, [
    licenseTypeLower,
    isNotDemo,
    isExchangesEmpty,
    gunthy_wallet,
    shouldShowAlertAddon,
    shouldRedirect,
    bitRageActive,
  ]);

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Switch>
        {/* Place the AutoConfig route outside the memoized routes to prevent re-renders on config changes */}
        <Route path="/autoconfig" component={AutoConfig} />
        {memoizedRoutes}
      </Switch>
    </Suspense>
  );
};

/**
 * Define prop types for the RouteComponent for better type checking and documentation.
 */
RouteComponent.propTypes = {
  config: PropTypes.object.isRequired,
};

/**
 * Custom comparison function for React.memo to prevent unnecessary re-renders.
 *
 * @param {Object} prevProps - The previous props.
 * @param {Object} nextProps - The next props.
 * @returns {boolean} - Returns true if props are equal, false otherwise.
 */
const arePropsEqual = (prevProps, nextProps) => {
  // Prevent re-rendering unless relevant parts of config have changed
  // You can customize this comparison to suit your needs
  return _.isEqual(prevProps.config, nextProps.config);
};

// Export the memoized RouteComponent to enhance performance by avoiding unnecessary re-renders
export default React.memo(RouteComponent, arePropsEqual);
